// Auth.js
import  React from 'react'; 
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

function useAccountAuth() { 
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // Verificar si el usuario está autenticado
  if (!isAuthenticated) {
    return null;
  }

   // Obtener información del usuario del primer usuario autenticado (puede haber múltiples si se usa multiusuario) 
   const userAccount = accounts[0];

   return userAccount;
}

export default useAccountAuth;
    