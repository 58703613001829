// userContext.js
import { createContext, useContext, useState , useEffect , useCallback} from 'react';
import Backdrop from '@mui/material/Backdrop'; 
import { getItemsGraph } from "./utils/GraphServices";
import useAccountAuth from "./hook/useAccountAuth";
import axiosInterceptor from './hook/setupAxiosInterceptors';

const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const idListMaestroDeClientes = process.env.REACT_APP_ID_MAESTRO_CLIENTES;
    const [user, setUser] = useState(null);
    const [blockUser, setBlockUser] = useState(false);
    const [userSharepoint, setUserSharepoint] = useState(null);
    const accountAuth = useAccountAuth();  
    const [counter, setCounter] = useState(0); 

    //TODO: Principios SOLID la responsabilidad de carga la debe tener otro provider
    const uploadProgressFnc = (progress) => {
        setUploadProgress(progress);
    }

    const [showLoader, setShowLoader] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Para rastrear el progreso de carga 
    const interceptorunit =  axiosInterceptor(setShowLoader, uploadProgressFnc);  
    

    useEffect(() => {
        let intervalId;
        if (showLoader) {
          intervalId = setInterval(() => {
            setCounter((prevCounter) => (prevCounter === 20 ? 0 : prevCounter + 1));
            setUploadProgress((prevCounter) => (prevCounter === 20 ? 0 : prevCounter + 1));
          }, 300);
        }
        return () => {
          if (intervalId) clearInterval(intervalId);
        };
      }, [showLoader]);
    
      const startLoader = useCallback(() => {
        setShowLoader(true);
      }, []);
    
      const stopLoader = useCallback(() => {
        setShowLoader(false);
        setCounter(0);
    }, []);
 

    useEffect(() => {  
        if (accountAuth !== null) {
            getItemsGraph(idListMaestroDeClientes, `/items?expand=fields&filter=fields/CorreoElectronico eq '${accountAuth.username}'`)
              .then((responseId) => {
                const response = responseId.data.value;   
                if (response.length === 0) {
                    // No se encuentra registrado en el sistema de clientes maestros
                    setBlockUser(true);
                } else { 
                    setUserSharepoint(response[0].fields); 
                } 
    
              })
              .catch((error) => {
                 // error en la conexion del maestro de clientes
                 console.error('error de conexion al maestro de clientes ', error);
              });
        }
    
        return () => {};
      }, [accountAuth, idListMaestroDeClientes]);

    return (
        <UserContext.Provider value={{ user, setUser , userSharepoint , showLoader , uploadProgress , startLoader , stopLoader }}>
            {
                blockUser ? 
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={blockUser} 
                    >
                      <h2 style={ { 'textAlign' : 'center' } }>No se encuentra registrado en el sistema de clientes maestros.</h2>
                    </Backdrop>
                </> :
                children
            }
        </UserContext.Provider>
    );
};
