// TokenContext.js
import React, { createContext, useState, useContext, useEffect } from 'react'; 
import { axiosInstance } from "../hook/setupAxiosInterceptors";
import { useMsal } from "@azure/msal-react";

const TokenContext = createContext(null);

export const TokenProvider = ({ children , pca }) => {
  const [token, setToken] = useState(null);
  const [tokenExpiresOn, setTokenExpiresOn] = useState(null);
  const { instance, accounts } = useMsal();

  function isTokenValid() {
    if (!token) return false;

    const now = new Date();
    if (now >= tokenExpiresOn) return false;

    return true;
  }

  async function fetchAccessToken() { 
    const request = {
      scopes: ["Chat.ReadWrite", "email", "Mail.Send", "offline_access", "openid", "profile", "Sites.Read.All", "Sites.ReadWrite.All", "User.Read",
        "User.ReadBasic.All", "AllSites.Manage", "AllSites.Read", "AllSites.Write", "EnterpriseResource.Read", "MyFiles.Read", "MyFiles.Write",
        "Project.Read", "Project.Write", "TaskStatus.Submit"], // O los scopes que necesites
    };

    try {
      if (accounts.length === 0) {
        // throw new Error("No account found");
        instance.loginRedirect(); // O puedes usar instance.loginPopup() si prefieres un popup
        return null;
      }

      instance.setActiveAccount(accounts[0]);

    
      const response = await instance.acquireTokenSilent(request);
      const expiresOn = new Date(response.expiresOn * 1000); // Convertir a fecha JS si 'expiresOn' está en segundos

      return {
        accessToken: response.accessToken,
        expiresOn,
      };
    } catch (error) {
   
       if (error?.errorCode) {
         console.log(error?.errorCode);
         if ( error?.errorCode === "login_required" || error?.errorCode === "consent_required" || error?.errorCode === "interaction_required") {
            instance.loginRedirect(); // Puedes manejar diferentes tipos de errores si es necesario
            return null;
         } else if (error?.errorCode === "invalid_grant") {  

           const loginRequest = {
             scopes: ["Chat.ReadWrite", "email", "Mail.Send", "offline_access", "openid", "profile", "Sites.Read.All", "Sites.ReadWrite.All", "User.Read",
               "User.ReadBasic.All", "AllSites.Manage", "AllSites.Read", "AllSites.Write", "EnterpriseResource.Read", "MyFiles.Read", "MyFiles.Write",
               "Project.Read", "Project.Write", "TaskStatus.Submit"], // Especifica aquí los scopes requeridos
             prompt: "consent" // Forza la aparición de la página de consentimiento
           };

         // await instance.loginRedirect(loginRequest);

          //  instance.logoutRedirect({
          //    postLogoutRedirectUri: "/login" // O la URL a la que deseas redirigir después del logout
          //  }).then(() => {
          //    // Otras acciones después del logout, si son necesarias
          //    localStorage.clear();
          //  });;

          //  instance.acquireTokenRedirect(loginRequest)
          //  await instance.loginRedirect();
           return null;
        }
       }
      console.error("Error fetching access token", error);
      return null;
    }
  }  

  const saveToken = (newToken, expiresOn) => {
    setToken(newToken);
    setTokenExpiresOn(expiresOn);
    saveTokenToLocalStorage(newToken, expiresOn); 
  };


  function saveTokenToLocalStorage(token, expiresOn) {
    localStorage.setItem("accessTokenBusint", token);
    localStorage.setItem("tokenExpiresOnBusint", expiresOn.toString());
  }

  function getTokenFromLocalStorage() {
    return {
      accessToken: localStorage.getItem("accessTokenBusint"),
      tokenExpiresOn: new Date(localStorage.getItem("tokenExpiresOnBusint")),
    };
  }


  useEffect(() => { 
    const requestInterceptor = axiosInstance.interceptors.request.use(
      async (config) => { 

        if (!isTokenValid()) {
          const newToken = await fetchAccessToken();
          if (newToken) {
            const { accessToken, expiresOn } = newToken;
            saveToken(accessToken, expiresOn); // Guardar en el contexto
  
            // Actualizar el token en la configuración de la solicitud actual
            config.headers["Authorization"] = `Bearer ${accessToken}`;
          }
        } else {
          // Si el token es válido, usar el token existente
          const { accessToken } = getTokenFromLocalStorage();
          if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
          }
        }
  
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    // Limpiar el interceptor cuando el componente se desmonte
    return () => {
      // axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [saveToken]); // Dependencias del useEffect 


  useEffect(() => {
    const initializeMsal = async () => {
      await pca.initialize(); // Initialize MSAL instance
      const tokenResponse = await fetchAccessToken(true);
      if (tokenResponse) {
        saveToken(tokenResponse.accessToken , tokenResponse.expiresOn);
      }
    };

    initializeMsal();

  }, []);


  return (
    <TokenContext.Provider value={{ token, tokenExpiresOn, saveToken , getTokenFromLocalStorage }}>
      {token !== null && children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = () => useContext(TokenContext);
