import React, { useEffect, useState } from "react";
import Form from "../../lib/solicitud-form/form-component";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../hook/useSnackbar"; 
import { useVariableContext } from "../../variableContext";
import {
  getItemsGraph,
  isObjectDeclaredAndHasProperties,
} from "../../utils/GraphServices";
import ModalConfirmation from "../../lib/modal-confirmation/modal-confirmation-component";
import FnSendRegister from "./FnSendRegister"; 
import { useUserContext } from '../../userContext';

export default function FrmSolicitud() { 
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [columnsFrm, setColumnsFrm] = useState([]);
  const { root } = useVariableContext();
  const { startLoader , stopLoader  } = useUserContext();
  
  const [infoFrm, setInfoFrm] = useState(() => {
    let defaultFrm = { idLista: "", Title: "", Icon: "" };
    if (root.ListadoApp) {
      defaultFrm = root.ListadoApp.find((x) => x.idLista === id);
    }else if (Object.keys(root).length === 0) {
      window.location.href = "/";
    }
    return defaultFrm;
  });


  const [dataFrm, setDataFrm] = useState(null); 
  const [fileUpload, setFileUpload] = useState([]); 
  const [showModalConfirmation, setModalConfirmation]= useState(false);

  useEffect(() => {
    startLoader();
    getItemsGraph(id, "contentTypes")
      .then((responseContent) => {
        let nameContentType = responseContent.data.value.find(
          (item) => item.name === process.env.REACT_APP_CLIENTE_REGISTRA_FRM
        )?.id;

        getItemsGraph(id, `/contentTypes/${nameContentType}/columns`)
          .then((response) => {
            if (response.data.value) {
              setColumnsFrm(
                response.data.value.filter((x) => x.columnGroup !== "_Hidden")
              );
            }
          })
          .catch((error) => {
            showSnackbar(
              "Error en obtener los campos del formulario, consulte con el admin.",
              "error"
            );
          })
          .finally(() => {
            stopLoader();
          });
      })
      .catch((error) => {
        stopLoader();
        showSnackbar(
          "Error en instanciar formulario, consulte con el admin.",
          "error"
        );
      });

    return () => {};
  }, []);


  const handleSendInfo = (dataFromChild) => { 
    const filteredData = filterObject(dataFromChild); 
    setDataFrm(filteredData);
    console.log(filteredData);
    if (isObjectDeclaredAndHasProperties(filteredData)) {
      setModalConfirmation(true);
    } else {
      setModalConfirmation(false);
    }
  }; 

  const filterObject = (obj) => {
    const filteredObj = {};

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
    });

    return filteredObj;
  };

  return (
    <>
      {/* Form & Modal Section 
                    info is on the first div, form on the second 
                */}
      {showModalConfirmation ? (
        <ModalConfirmation
          setModalConfirmation={setModalConfirmation}
          sendRegister={FnSendRegister}
          columnsFrm={columnsFrm}
          fileUpload={fileUpload}
          dataFrm={dataFrm}
          infoFrm={infoFrm}
        />
      ) : (
        <></>
      )}
      <div>
        {SnackbarComponent}
        <div className="back" onClick={() => navigate(-1)}>
          Volver
        </div>
        {/* <h2>Tipo de Solicitud</h2> */}
        <h4>{infoFrm.Title}</h4>
        <p>
          <b>
            Por favor, complete el siguiente formulario para que podamos atender
            su solicitud de manera efectiva.
          </b>
          <br />
          <br />
          Nos aseguraremos de que su solicitud sea asignada a nuestro equipo de
          expertos y se le brinde una solución de manera oportuna. Agradecemos
          su paciencia y colaboración mientras trabajamos juntos en resolverlo.
          <br />
        </p>
      </div>
      <div>
        {columnsFrm.length > 0 && (
          <Form
            columnsFrm={columnsFrm}
            handleSendInfo={handleSendInfo}
            setFileUpload={setFileUpload}
            fileUpload={fileUpload}
            key={"idForm" + id}
          ></Form>
        )}
      </div>
      {/* End Form & Modal Section */}
    </>
  );
}
