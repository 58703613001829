import { useEffect } from 'react';
import './header-component.scss';
import Logo from '../../lib/logo/logo-component';
import Search from '../../lib/search/search-component';
import Profile from '../../lib/profile/profile-component';
import { useMsal } from "@azure/msal-react";

export default function Header() {

    const { instance, accounts, inProgress } = useMsal();
   

    const propsProfile = () => {
        
        instance.logoutRedirect({
            postLogoutRedirectUri: "/login" // O la URL a la que deseas redirigir después del logout
          }).then(() => {
            // Otras acciones después del logout, si son necesarias
            localStorage.clear();
          });;
    } 

    return (
        <header className='header'>
            <div className='header__container'>
                <Logo accounts={accounts[0]} ></Logo>
                <div>
                    <Search></Search>
                    <Profile accounts={accounts[0]} onClick={propsProfile}  ></Profile>
                </div>
            </div>
        </header>
    )
}