import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoSummary from '../../lib/info-summary/info-summary-component';
import ChatComponent from '../../lib/chat/chat-component';
import { initValueFrm } from "../../utils/variables";
import { useVariableContext } from "../../variableContext";

export default function SolicitudDetalle() {
 
    const { idForm , idSolicitud } = useParams();
    const navigate = useNavigate();
    const [infoFrm, setInfoFrm] = useState(initValueFrm);
    const { root } = useVariableContext(); 
    const [item , setItem] = useState({}); 
  
    useEffect(() => {
      if (Object.keys(root).length === 0) {
        navigate('/');
      } else if (root.ListadoApp) {
        const defaultFrm = root.ListadoApp.find(x => x.idLista === idForm) || initValueFrm;
        setInfoFrm(defaultFrm);
      }
    }, [root, idForm, navigate]);

    return (
        <>
            {/* Info Summary + chat section
                */}
            <InfoSummary 
                idForm={idForm}
                idSolicitud={idSolicitud}
                infoFrm={infoFrm}
                item={item}
                setItem={setItem}
            />
            <ChatComponent 
              idForm={idForm}
              idSolicitud={idSolicitud}
              item={item}
              infoFrm={infoFrm}/>
            {/* End Info Summary + chat
                */}
        </>
    );
}