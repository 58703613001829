import { axiosInstance } from "../hook/setupAxiosInterceptors";
import axios from "axios"; 

const ROOT_GRAPH = "https://graph.microsoft.com/v1.0";
const URL_GRAPH = ROOT_GRAPH + "/sites";


export const getUserGraph = (idLista, params)  => (
  axiosInstance.get( `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idLista}/${params}` , {
    headers: {
        'Prefer': 'HonorNonIndexedQueriesWarningMayFailRandomly'
    }
  })
) 

export const getItemsGraph = (idLista, params) => {
  return axiosInstance.get(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idLista}/${params}`
  );
};

export const getItemsGraphCount = (idLista, params) => {

 return axiosInstance({
      method: 'get',
      url: `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idLista}/${params}$count=true`,
      headers: {
          'ConsistencyLevel': 'eventual'
      }
  });
};


export const getrequestGraph = (url) => {
  return axiosInstance.get(
    url
  );
};


export const SET_ITEMS_GRAPH = (idLista, params , bodyParams) => {
  return axiosInstance.post(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idLista}/${params}`,
    bodyParams
  );
};


export const UPDATE_FOLDER_LINK = (idLista, params, bodyParams) => { 
  return axiosInstance.post(
    `/setFolderLink/${idLista}/${params}`,
    bodyParams
  );
};

export const UPDATE_ITEMS_GRAPH = (idLista, params, bodyParams) => { 
  return axiosInstance.patch(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idLista}/${params}`,
    bodyParams
  );
}; 


export const GET_REQUEST_GRAPH = (endpoint) => {
  return axiosInstance.get(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/${endpoint}`
  );
};

export const POST_REQUEST_GRAPH = (endpoint, bodyParams) => {
  return axiosInstance.post(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/${endpoint}`,
    bodyParams
  );
};

export const PUT_REQUEST_GRAPH = (endpoint, bodyParams) => {
  return axiosInstance.put(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/${endpoint}`,
    bodyParams
  );
};


export const SEND_EMAIL_GRAPH = (userEmail, bodyParams) => {
  return axiosInstance.post(
    `${ROOT_GRAPH}/users/${userEmail}/sendMail`,
    bodyParams
  );
};

export const CREATE_UPLOAD_SESSION = (parentId , fileName ) => {
  return axiosInstance.post(
    `${URL_GRAPH}/${process.env.REACT_APP_SITE_SHAREPOINT}/drives/${parentId}/root:/${fileName}:/createUploadSession`,
    {}
  );
}

export const SUBSCRIPTIONS_ITEM = (bodyRequest) => {
  return axiosInstance.post(
    `${ROOT_GRAPH}/subscriptions`,
    bodyRequest
  );
}

export const POST_REQUEST = (url, bodyRequest) => {
  return axios.post(url,
    bodyRequest
  );
}

export const CHUNK_FILE = (uploadUrl , contentRange , chunk) => {  
  return axiosInstance.put(
    uploadUrl,
    chunk,
    {
      headers: {
          'Content-Range': contentRange
      } 
    }
  );
}


export  const isObjectDeclaredAndHasProperties = (obj) => {
  // Verifica si obj es un objeto y no es null
  if (typeof obj === "object" && obj !== null) {
    // Verifica si el objeto tiene al menos una propiedad propia
    return Object.keys(obj).length > 0;
  }
  // Si obj no es un objeto o es null, retorna false
  return false;
};