import './info-summary-component.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getItemsGraph
  } from "../../utils/GraphServices";
import { useUserContext } from "../../userContext"; 
import { useSnackbar } from "../../hook/useSnackbar";
import CustomCell from "../custom-cell/custom-cell";

export default function InfoSummary({ infoFrm, idForm, idSolicitud, item, setItem }) {

   
    const [panelTrigger, activePanelTrigger] = useState(false);
    const { userSharepoint , startLoader , stopLoader   } = useUserContext();
    const navigate = useNavigate();
    const [columnsFrm, setColumnsFrm] = useState([]);
    const { showSnackbar } = useSnackbar(); 

    const activePanel = () => {
        if(panelTrigger){
            activePanelTrigger(false);
        } else {
            activePanelTrigger(true);
        }
        
    };


    useEffect(() => {
        const fetchData = async () => {
          try {
            if (userSharepoint?.id === undefined) {
                Location.reload();
            }

            await startLoader();
            
            const responseContent = await getItemsGraph(idForm, "contentTypes");  
            const nameContentType = responseContent.data.value.find(
              (item) => item.name === process.env.REACT_APP_CLIENTE_MUESTRA_INFO
            )?.id;
    
            // Obtener las columnas referentes al tipo de contenido
            const responseColumns = await getItemsGraph(idForm, `/contentTypes/${nameContentType}/columns`); 
            setColumnsFrm(responseColumns.data.value.filter((x) => x.columnGroup !== "_Hidden")); 
            console.log(responseColumns.data.value.filter((x) => x.columnGroup !== "_Hidden"))
            
            const responseItems = await getItemsGraph(idForm, `/items/${idSolicitud}?expand=fields`);
 
            setItem(responseItems.data.fields);  
            await stopLoader();
          } catch (error) {
            await stopLoader();
            console.log(error)
            showSnackbar(
              "Error : " + error.message,
              "error"
            );
          }
     
        };
    
        fetchData();
     
        return () => {
          // Cualquier código de limpieza aquí
        };
    }, []);  
 

    return ( 
        <div className='info-summary leftSide'> {/* remove class leftSide when want when panel is at the right and add when panel is on the left */}
            {
                item && Object.keys(item).length > 0 ? 
                <div className="info-summary__container">

                    {/* breadcrumb block, add when necessary ok */}
                    <div className="back" ><span onClick={() => navigate('/Home')} >Home</span>/<span onClick={() => navigate(-1)} >Listado de {infoFrm.Title}</span>/ Solicitud</div>

                    {/* cancel block, move above body when the panel is on the left, move after body when is on the right*/}
                    {/* 
                    // TODO: aplicar la cancelacion de una solicitud
                    <div className="info-summary__btn">
                        <span className="timer">{formatTime(seconds)}</span>
                        <p>seg Para Cancelar la Solicitud</p>
                        <button className='cancel'>Cancelar Solicitud</button>
                    </div> */}

                    <div className={`${panelTrigger ? 'info-summary__body active' : 'info-summary__body'}`}>
                        <h3><span>Solicitud /</span> {infoFrm.Title} <span className={"state" + (item['Estado'] === 'Finalizado' ? '--confirmed' : '')} > {item['Estado']}</span></h3>{/* process state, add "--confirmed" for green result */}
                        {/* <h2>Solicitud de soporte para optimizar el proceso de facturación</h2> */}
                        <div>
                            {
                                columnsFrm.filter(x => x.displayName !== 'Estado' ).map((columnKey, colIndex) => (
                                    <div key={'columnaShow_' + colIndex}>
                                        <div>
                                            <label>{columnKey.displayName}</label>
                                            <span>{columnKey.description}</span>
                                        </div>
                                        <div>
                                            {
                                                item && 
                                                <CustomCell columnKey={columnKey} rowData={item} />
                                            }
                                            {/* <img src={process.env.PUBLIC_URL + '/assets/posint.svg'} alt="" className=''/> */}
                                        </div>
                                    </div> 
                                ))
                            } 
                            {/* <div>
                                <div>
                                    <label>Archivos Adjuntos</label>
                                </div>
                                <div className='img'>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/assets/img-ex.png'} alt="" className=''/>
                                        <span>JPG</span>
                                        <p>Descripción de la imagen que soporta el problema</p>
                                    </div>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/assets/img-ex2.png'} alt="" className=''/>
                                        <span>PDF</span>
                                        <p>Descripción de la imagen que soporta el problema</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="show-content" onClick={activePanel}>Desplegar todo el detalle</div>
                    </div>

                </div> :
                <div></div>
            }
        </div>
    )
}