import React, { ReactElement, Ref } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SEND_EMAIL_GRAPH } from "../../utils/GraphServices";
import useAccountAuth from "../../hook/useAccountAuth";
import useToken from "../../hook/useToken";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>,
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogError({ open, setOpen, response , initialData}) { 

  const accountAuth = useAccountAuth(); 
  const token = useToken();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMail = () => {
    sendEmail()
      .then((response) => {})
      .catch((resp) => {
        token.fetchAccessToken().then((response) => console.log(response));
      });
  };

  // Envía un correo electrónico usando Microsoft Graph
  async function sendEmail() { 
 
     const mailContent = {
       subject:
         "Hola tenemos un error en la APP de solicitudes con id codigo de error : " +
         response.code +
         " en las fechas " +
         new Date().toISOString(),
       body:
         "Esta fue la respuesta de error: " +
         JSON.stringify(response) +
         " <br>  y esta fue la data inicial enviada : " +
         JSON.stringify(initialData),
       to: process.env.REACT_APP_MAIL_SUPPORT,
       cc: [],
     };

    const mail = {
      message: {
        subject: mailContent.subject,
        body: {
          contentType: "HTML",
          content: mailContent.body,
        },
        toRecipients: [
          {
            emailAddress: {
              address: mailContent.to,
            },
          },
        ],
        ccRecipients: mailContent.cc.map((email) => ({
          emailAddress: { address: email },
        })),
      },
      saveToSentItems: "true",
    };

    try {
      await SEND_EMAIL_GRAPH(accountAuth.username, mail);
      console.log("Email sent successfully");
      handleClose();
    } catch (error) {
      console.error("Error sending email", error);
      throw error;
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Reporte de Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Parece que algo no salió bien. Ayúdanos a mejorar enviando un reporte.
          <br></br>
          Detalle del error : {response.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ignorar</Button>
        <Button onClick={handleSendMail}>Enviar Reporte</Button>
      </DialogActions>
    </Dialog>
  );
}
