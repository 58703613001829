import "./main-component.scss"; 
import { Routes, Route, Navigate } from "react-router-dom";

// Pages
import Login from "../../pages/Login/Login";
import Home from "../../pages/Home/Home";
import FrmSolicitud from "../../pages/FrmSolicitud/FrmSolicitud";
import EnvioSuccess from "../../pages/EnvioSuccess/EnvioSuccess";
import Listado from "../../pages/Listado/Listado";
import SolicitudDetalle from "../../pages/SolicitudDetalle/SolicitudDetalle";

// Hooks
import useAuth from "../../hook/useAuth"; 

export default function Main() {
  const isAuthenticated = useAuth();  

  return (
    <main className="main">
      <section className="main__container">
        <Routes> 
          <Route
            path="/Login"
            element={isAuthenticated ? <Navigate to="/Home" /> : <Login />}
          />
          <Route
            path="/Home"
            element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
          />
          <Route
            path="/FrmSolicitud/:id"
            element={
              isAuthenticated ? <FrmSolicitud /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/EnvioSuccess"
            element={
              isAuthenticated ? <EnvioSuccess /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/Listado/:idForm"
            element={isAuthenticated ? <Listado /> : <Navigate to="/login" />}
          />
          <Route
            path="/Listado/:idForm/:idSolicitud"
            element={
              isAuthenticated ? <SolicitudDetalle /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/Home" />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />
        </Routes>
      </section>
    </main>
  );
}
