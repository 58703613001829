import "./visor.scss";
import { useEffect } from 'react'; 
import FileViewer from 'react-file-viewer';


const file = 'http://localhost:3000/assets/envio-solicitud-img.png'
const type = 'docx'


const CustomErrorComponent = () => (
    <h3>Error al visualizar el documento solicitado</h3>
)

export default function Visor() {



    const onError = (e) => {
        console.log(e, 'error in file-viewer');
    }
  
    return ( 
        <div className="fileViewer">
            <FileViewer
                style={{ with: "100%", height: "100%" }}
                fileType={type}
                filePath={file}
                errorComponent={CustomErrorComponent}
                onError={onError} />
        </div> 
    )

}