import { useEffect } from "react";

export default function InputNoteComponent({ props, register }) {
  
    const inputNoteProps = {
        name: props.name,
        id: props.id, 
        ...(props.required ? { required: true } : null),
        ...register(props.name)
    };


    return (
        props.hidden ? <></> :
        <div style={{width : "100%" }}>
            <label htmlFor="">
                {props.displayName}
                <span>{props.description}</span>
            </label>
                <textarea  {...inputNoteProps}  cols="30" rows="10" ></textarea>
                {props.required ? <span className='error-message'>Debes elegir {props.displayName}</span> : <></> } 
        </div>
    )
}