import { useState , useEffect} from 'react';
import Switch from '@mui/material/Switch';


export default function InputBooleanComponent({ props, register , setValue}) { 

    const [switchState, setSwitchState] = useState(false);
  

    const inputTextProps = {
        name: props.name,
        id: props.id, 
        checked: switchState, 
        onChange: (event) => { 
            setValue(props.name, event.target.checked);
            setSwitchState(event.target.checked);
        },
        inputProps: { 'aria-label': props.name },
        ...(props.required ?  { required: true } : {} )
    }; 

    useEffect(() => {
        if (props) {      
            register(props.name , { value: props?.defaultValue?.value === '1' } );
            setSwitchState(props?.defaultValue?.value === '1');
            setValue(props.name, props?.defaultValue?.value === '1'); 
            return () => {};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        props.hidden ? <></> :
        <div>
            <label htmlFor="">
                {props.displayName}
                <span>{props.description}</span>
            </label> 
            <Switch {...inputTextProps}  />
            {props.required ? <span className='error-message'>Debes elegir {props.displayName}</span> : <></>}
        </div>
    )
}