// axiosInterceptor.js
import axios from "axios";  
// Crear una instancia de Axios
const axiosInstance = axios.create();
let intervalId = null;
let progress = 0;  

const setupAxiosInterceptors = (loaderFunction, UploadProgressFunction) => {
  let requestCounter = 0;  

  // axiosInstance.defaults.baseURL = "http://localhost:7071/api";
  axiosInstance.defaults.baseURL = process.env.REACT_APP_URL_API_INTERNAL;

  // Configura un interceptor para solicitudes HTTP antes de enviarlas
  axiosInstance.interceptors.request.use(
    (config) => {
      requestCounter++;
      config.onDownloadProgress = (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
            );
            // UploadProgressFunction(percentCompleted);
            console.log(`Descarga: ${percentCompleted}% completado`);
        } else {
          // Mostrar barra de progreso indeterminada  
          if (progress > 20) {
            progress = 0; // reiniciar a 0 si alcanza 100
          }
          // UploadProgressFunction(progress);
          progress = progress + 1;
        }
        // Puedes realizar otras acciones aquí según el progreso de descarga
        // Muestra el loader cuando se inicia una solicitud
        // loaderFunction(true);
        return config;
      };
      return config;
    },
    (error) => {
      // manejo de errores
    }
  );

  // Configurar un interceptor para monitorear el progreso de carga
  axiosInstance.interceptors.request.use((config) => {
    config.onUploadProgress = (progressEvent) => { 
      if (progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );  
        // Puedes realizar otras acciones aquí según el progreso de carga
        // loaderFunction(false);
      }
      return config;
    };
    return config;
  });

  // Configura un interceptor para respuestas HTTP
  axiosInstance.interceptors.response.use(
    (response) => { 
      requestCounter--;
      // Oculta el loader cuando todas las solicitudes están completas
      progress = 0;
      // UploadProgressFunction(progress);
      if (requestCounter === 0) {
        // loaderFunction(false);
      }
      return response;
    },
    (error) => {
      requestCounter--;
      // Oculta el loader en caso de error
      if (requestCounter === 0) {
        // loaderFunction(false);
      }
      return Promise.reject(error);
    }
  );
};
 
export let hasInterceptor = false;
export default setupAxiosInterceptors;
export { axiosInstance };