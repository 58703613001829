import React, { useEffect, useRef } from 'react';
import './loader-component.scss';

export default function Loader({ currentIndex = 0 }) {
    const loaderRef = useRef(null);
  
    useEffect(() => {
        const images = [
          process.env.PUBLIC_URL + '/assets/loader/logo1.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo2.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo3.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo4.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo5.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo6.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo7.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo8.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo9.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo10.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo11.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo12.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo13.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo14.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo15.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo16.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo17.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo18.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo20.svg',
          process.env.PUBLIC_URL + '/assets/loader/logo20.svg',
        ];
    
        const interval = 400;
        const totalImages = images.length;
        // let currentIndex = porcentaje; // Se calcula de 0 a 20 para mostrar cada imagen
    
        const changeImage = () => {
          const currentImage = images[currentIndex];
          let backgroundSize = 'cover';
          let backgroundColor = '';
    
          if (currentIndex >= totalImages - 3) {
            const progress = (currentIndex - (totalImages - 3)) / 2;
            backgroundSize = `${100 + progress * 100}%`;
    
            if (currentIndex === totalImages - 1) {
              backgroundColor = 'white';
            }
          }
    
          loaderRef.current.style.backgroundImage = `url(${currentImage})`;
          loaderRef.current.style.backgroundSize = backgroundSize;
          loaderRef.current.parentElement.style.backgroundColor = backgroundColor;
    
          currentIndex = (currentIndex + 1) % totalImages;
        };

      changeImage();
    
        // const intervalId = setInterval(changeImage, interval);
    
        return () => {
          // clearInterval(intervalId);
        };
    }, [currentIndex]);
    
      return (
        <div className='loader'>
          <div className='loader__container'>
            <div 
              className='loader__item' 
              ref={loaderRef}
              ></div>
          </div>
        </div>
      );
}