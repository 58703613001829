import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../hook/useSnackbar"; 
import { useUserContext } from "../../userContext"; 
import { getUserGraph } from "../../utils/GraphServices"

export default function InputSelectComponent({ props, register, setValue }) {
  
    const { userSharepoint } = useUserContext(); 
    const [listOpt, setListOpt] = useState([]);
    const { showSnackbar } = useSnackbar();   
    const idListaCliente =  process.env.REACT_APP_ID_MAESTRO_CLIENTES;  
    const is_field_Client = idListaCliente === props?.lookup?.listId;
    const prefix_Field = props?.type === "choice" ? '' : 'LookupId'
    
 
    useEffect(() => {
        if (props.type === "lookup" && listOpt.length === 0) {
            if (is_field_Client) {  
                setValue(props.name + prefix_Field, userSharepoint?.id); 
                setListOpt([{ id: userSharepoint?.id, CorreoElectronico: userSharepoint?.CorreoElectronico }])
            } else { 
                getUserGraph(props.lookup.listId, "/items?expand=fields").then((response) => {
                    setListOpt(response.data.value.map(itemSelect => itemSelect.fields));
                })
                .catch((error) => {
                    showSnackbar(
                        "Error en obtener los campos del formulario, consulte con el admin.",
                        "error"
                    );
                });
            }
        }

        return () => {}
    }, [])
 

    const selectProps = {
        name: props.name + prefix_Field,
        id: props.id,
        disabled: is_field_Client,
        ...(props.required ? { required: true } : {}),
        ...register(props.name + prefix_Field , {
            value: is_field_Client ? userSharepoint.id : props?.defaultValue?.value
        })
    };


    return (
        props.hidden ? <></> :
            <div >
                <label htmlFor="">
                    {props.displayName}
                    <span>{props.description}</span>
                </label>
                <select {...selectProps}  > 
                    <option value="" key={props.id + 'empty'}></option>
                    {
                        props.type === "choice" ?
                            props.choice.choices.map((item , index) => <option value={item} key={props.id + '__' +index} >{item}</option>) :
                            listOpt.map(result => <option value={result.id} selected={is_field_Client ? 'selected' : null} disabled={is_field_Client} key={props.id + result.id} >{result[props.lookup.columnName]}</option>)
                    }
                </select>
                {props.required ? <span className='error-message'>Debes elegir {props.displayName}</span> : <></>}
            </div>
    )
}