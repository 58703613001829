import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export default function Login() {
  const { instance, accounts, inProgress } = useMsal(); 

  useEffect(() => {
    async function fetchData() {
      if (accounts.length === 0) {
        await instance.loginRedirect(); 
      }
    }
    fetchData();  
    // code to run after render goes here
  }, []);
  

  if (accounts.length > 0) {
    return <span>  Hay actualmente {accounts.length} Usuarios iniciados!</span>;
  } else if (inProgress === "login") {
    return <span>¡El inicio de sesión está actualmente en progreso!</span>;
  } else {
    return (
      <>
        <span>¡Actualmente no hay usuarios autenticado!</span>
        <button onClick={() => instance.loginPopup()}>Iniciar Sesión</button>
      </>
    );
  }
}
