
import './table-component.scss';
import { useVariableContext } from "../../variableContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import CustomCell from '../custom-cell/custom-cell';

const initValueFrm = {
    "@odata.etag": "",
    "Title": "Soporte Administrativo - Operativo",
    "idLista": "6cad4cda-8b4d-4cfd-aea3-c4ad2f82cd28",
    "Habilitado": true,
    "Icon": "verde_intenso",
    "id": "1",
    "ContentType": "Elemento",
    "Modified": "2023-10-22T09:35:32Z",
    "Created": "2023-03-05T16:09:06Z",
    "AuthorLookupId": "9",
    "EditorLookupId": "9",
    "_UIVersionString": "8.0",
    "Attachments": false,
    "Edit": "",
    "LinkTitleNoMenu": "Soporte Administrativo - Operativo",
    "LinkTitle": "Soporte Administrativo - Operativo",
    "ItemChildCount": "0",
    "FolderChildCount": "0",
    "_ComplianceFlags": "",
    "_ComplianceTag": "",
    "_ComplianceTagWrittenTime": "",
    "_ComplianceTagUserId": ""
}


export default function Table({ columnsFrm = [],valuesRegister = [] , nextPageLoader , nextLink = '' }) { 

    
    const { root } = useVariableContext();
    const navigate = useNavigate();
    const { idForm } = useParams();
    const [infoFrm, setInfoFrm] = useState(initValueFrm);
  
    useEffect(() => {
      if (Object.keys(root).length === 0) {
        navigate('/');
      } else if (root.ListadoApp) {
        const defaultFrm = root.ListadoApp.find(x => x.idLista === idForm) || initValueFrm;
        setInfoFrm(defaultFrm);
      }
    }, [root, idForm, navigate]);

    // Mapeo de tipos a clases
    const typeToClass = {
        'choice': 'state',
        'tipo2': 'tipo2-class',
        'tipo3': 'tipo3-class',
        // Puedes añadir más tipos y sus clases correspondientes aquí
    }; 
 
    return (
        <div className='table-component '> {/*toggle class "skeleton for skeleton loader effect"*/}
            <div className='table-component__container'>
                <section className="table-top">
                    <div>
                        <div onClick={() => navigate(-1)} className="back">Volver</div>
                    </div>
                    <div>
                        {/* <p>Suporte Administrativo /</p> */}
                        <button onClick={() => navigate('/FrmSolicitud/' + infoFrm.idLista)}>Realizar Nueva Solicitud</button>
                    </div>
                </section>
                <section className="table-body">
                    <div>
                        <h4>{infoFrm.Title}</h4> 
                    </div>
                    <div className="pagination">
                        {/* TODO: Aplicar la paginación hacia atras */}
                        {
                            nextLink && <button className="right" onClick={nextPageLoader} >Siguiente Página</button> 
                        }
                    </div>
                  
                    {<div className='table' style={{ overflowX: 'auto' }} >
                        <table style={{ tableLayout: 'auto' }} >
                            <thead>
                                <tr>
                                    <th></th>
                                    {
                                        columnsFrm.map((columName, index) =>
                                          <th key={'columnaShow_' + index}>{columName.displayName}</th>
                                        )
                                    }
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {valuesRegister.map((rowData, rowIndex) => (
                                    <tr key={'rowData' + rowIndex}>
                                        <td className='avatar'>
                                            <img src={process.env.PUBLIC_URL + '/assets/icon/'+ infoFrm.Icon +'.svg'} alt='' />
                                        </td>
                                        {
                                            columnsFrm.map((columnKey, colIndex) => (
                                                <td key={colIndex} className={columnKey.displayName === 'Estado' ? typeToClass[columnKey.type] || '' : '' }>
                                                    <CustomCell columnKey={columnKey} rowData={rowData} />
                                                </td>
                                            ))
                                        }
                                        <td className='view'>
                                            <button onClick={() => navigate(`/Listado/${infoFrm.idLista}/${rowData.id}`)}></button>
                                        </td>

                                    </tr>   
                               ))}
                            </tbody>
                            <tfoot>
                                {/*
                                // TODO: PEndiente aplicar notificaciones
                                <tr>
                                    <td colSpan={columnsFrm.length + 1}>
                                        <label className='check'>
                                            Deseo recibir notificaciones en mi escritorio de windows
                                            <input type='checkbox' />
                                            <span></span>
                                        </label>
                                    </td>
                                </tr> */}
                            </tfoot>
                        </table>
                    </div>}
                </section>
            </div>
        </div>
    )
}






// {<div className='table'>
// <table>
//     <thead>
//         <tr>
//             <th></th>
//             <th >ID</th>
//             <th >Fecha</th>
//             <th >Titulo</th>
//             <th >Descripción</th>
//             <th >Estado</th>
//             <th >Ult. Modificación</th>
//             <th ></th>
//         </tr>
//     </thead>
//     <tbody>
//         <tr>
//             <td className='avatar'>
//                 <img src={process.env.PUBLIC_URL + '/assets/square-green.svg'} alt="" />
//             </td>
//             <td>
//                 <p className='bold'>0000001</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td>
//                 <p>Soporte para optimizar el proceso de facturación</p>
//             </td>
//             <td>
//                 <p>
//                     Esta solicitud se refiere a la necesidad de mejorar y optimizar el proceso de facturación de la empresa texti...
//                 </p>
//             </td>
//             <td className='state'>
//                 <p>En proceso</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td className='view'>
//                 <button onClick={() => navigate('/Listado/idForm/idSolicitud')}></button>
//             </td>
//         </tr>
//         <tr>
//             <td className='avatar'>
//                 <img src="" alt="" />
//             </td>
//             <td>
//                 <p className='bold'>0000001</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td>
//                 <p>Soporte para optimizar el proceso de facturación</p>
//             </td>                                    <td>
//                 <p>
//                     Esta solicitud se refiere a la necesidad de mejorar y optimizar el proceso de facturación de la empresa texti...
//                 </p>
//             </td>
//             <td className='state'>
//                 <p className='confirmed'>Resuelta</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td className='view'>
//                 <button onClick={() => navigate('/Listado/idForm/idSolicitud')}><span className='notification'></span></button>
//             </td>
//         </tr>
//         <tr>
//             <td className='avatar'>
//                 <img src="" alt="" />
//             </td>
//             <td>
//                 <p className='bold'>0000001</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td>
//                 <p>Soporte para optimizar el proceso de facturación</p>
//             </td>
//             <td>
//                 <p>
//                     Esta solicitud se refiere a la necesidad de mejorar y optimizar el proceso de facturación de la empresa texti...
//                 </p>
//             </td>
//             <td className='state'>
//                 <p>En proceso</p>
//             </td>
//             <td>
//                 <p className='bold'>3 abr, 2023</p>
//             </td>
//             <td className='view'>
//                 <button onClick={() => navigate('/Listado/idForm/idSolicitud')}></button>
//             </td>
//         </tr>
//     </tbody>
//     <tfoot>
//         <tr>
//             <td colSpan={7}>
//                 <label class="check">Deseo recibir notificaciones en mi escritorio de windows
//                     <input type="checkbox" />
//                     <span></span>
//                 </label>
//             </td>
//         </tr>
//     </tfoot>
// </table>
// </div>}