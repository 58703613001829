// Auth.js
import  React from 'react'; 
import { useIsAuthenticated } from "@azure/msal-react";

function useAuth() { 
    const isAuthenticated = useIsAuthenticated();
    return isAuthenticated;
}

export default useAuth;
