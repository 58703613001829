import './solicitud-end-process-component.scss';

export default function SolicitudeEndProcess() {
    return (
        <div className='end-process'>
            <div className="end-process__container">
                <div>
                    <h3>¡Gracias por enviar su solicitud!</h3>
                    <h5>Vamos a estar trabajando para resolver su solicitud lo antes posible</h5>
                </div>
                <img src={process.env.PUBLIC_URL + '/assets/envio-solicitud-img.png'} alt="logotipo" />
                <div className="btn">
                    <button className='back'>Volver al inicio</button>
                    <button>Ver Listado de Solicitudes</button>
                </div>
            </div>
        </div>
    )
}