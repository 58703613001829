import './search-component.scss';
import React, { useState, useEffect } from 'react';


export default function Search(props) {

    const [isBelow690, setIsBelow690] = useState(window.innerWidth < 690);

    useEffect(() => {
      const handleResize = () => {
        setIsBelow690(window.innerWidth < 690);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleClick = () => {
        setIsBelow690(false);
      };

    return (
      <></>
        // TODO: implementacion pendiente
        // <div className={isBelow690 ? 'search collapsed' : 'search'} onClick={handleClick}>
        //     <input type="text"  placeholder='Buscar solicitud....'/>
        // </div>
    )
}