
import './resume-table-component.scss';
import { useNavigate } from 'react-router-dom';

// TODO: pendiente de espeficicar los resumenes de registros
export default function ResumeTable() {

    const navigate = useNavigate();


    return (
        <div className='table-component transparent'> {/*toggle class "skeleton for skeleton loader effect" || toggle class "transparent" for no background color*/}
            <div className='table-component__container'>
                <section className="table-body">
                    <div className='table'>
                        {/* <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th >ID</th>
                                    <th >Fecha</th>
                                    <th >Titulo</th>
                                    <th >Descripción</th>
                                    <th >Estado</th>
                                    <th >Ult. Modificación</th>
                                    <th ></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='avatar'>
                                        <img src={process.env.PUBLIC_URL + '/assets/square-green.svg'} alt="" />
                                    </td>
                                    <td>
                                        <p className='bold'>0000001</p>
                                    </td>
                                    <td>
                                        <p className='bold'>3 abr, 2023</p>
                                    </td>
                                    <td>
                                        <p>Soporte para optimizar el proceso de facturación</p>
                                    </td>
                                    <td>
                                        <p>
                                        Esta solicitud se refiere a la necesidad de mejorar y optimizar el proceso de facturación de la empresa texti...
                                        </p>
                                    </td>
                                    <td className='state'>
                                        <p>En proceso</p>
                                    </td>
                                    <td>
                                        <p className='bold'>3 abr, 2023</p>
                                    </td>
                                    <td className='view'>
                                        <button onClick={() => navigate('/Listado/idForm/idSolicitud')}></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                </section>
            </div>
        </div>
    )
}