import './logo-component.scss';
import { useNavigate } from "react-router-dom";

export default function Logo(props) {

    const { accounts } = props;
    const navigate = useNavigate();

    return (
        <div className='logo' onClick={() => navigate(-1)}>
            <figure>
                <img src={process.env.PUBLIC_URL + '/assets/logo.svg'} alt="logotipo" />
            </figure>
            <div className="title">
                <h3>!Hola {accounts?.name}!</h3>
                <h4>¿En qué podemos ayudarlo hoy?</h4>
            </div>
        </div>
    )
}