import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "../../hook/useSnackbar";
import { useUserContext } from "../../userContext";
import {
  getItemsGraph,
  getrequestGraph
} from "../../utils/GraphServices";
import Table from '../../lib/table/table-component'; 

export default function Listado() {

  const { idForm } = useParams();
  const { showSnackbar } = useSnackbar(); 
  const { userSharepoint , startLoader , stopLoader   } = useUserContext();
  const [columnsFrm, setColumnsFrm] = useState([]);
  const [valuesRegister , setValuesRegister] = useState([]);  
  const [nextLink , setNextLink] = useState('');


  const idListSolicitudes = idForm; 
  const cantidadElementos = 10; //Cantidad de elementos por pagina;


  useEffect(() => {
    const fetchData = async () => {
      try {
        await startLoader();
        // Obtener la lista de tipos de contenidos
        const responseContent = await getItemsGraph(idListSolicitudes, "contentTypes"); 

        const nameContentType = responseContent.data.value.find(
          (item) => item.name === process.env.REACT_APP_CLIENTE_MUESTRA_INFO
        )?.id;

        // Obtener las columnas referentes al tipo de contenido
        const responseColumns = await getItemsGraph(idListSolicitudes, `/contentTypes/${nameContentType}/columns`);
 
        setColumnsFrm(responseColumns.data.value.filter((x) => x.columnGroup !== "_Hidden")); 

        await loaderDataInit(); 
        await stopLoader();
      } catch (error) {
        await stopLoader();
        console.log(error)
        showSnackbar(
          "Error : " + error.message,
          "error"
        );
      }
 
    };

    fetchData();
 
    return () => {
      // Cualquier código de limpieza aquí
    };
  }, []);  


  const loaderDataInit = async (urlNext = '') => {

    if (userSharepoint.id === undefined) {
      Location.reload();
    }

    let linkInit = `/items?expand=fields&filter=fields/ClienteLookupId eq '${userSharepoint.id}'&$top=${cantidadElementos}`;

    let responseItems = null;
    if (urlNext !== '') {
      linkInit = urlNext;
      responseItems = await getrequestGraph(urlNext)
    }else {
      responseItems = await getItemsGraph(idListSolicitudes, linkInit);
    }

    setValuesRegister(
      responseItems.data.value.map(item => item.fields)
    );

    if (responseItems.data['@odata.nextLink']) {
      setNextLink(responseItems.data['@odata.nextLink']);
    }else {
      setNextLink('');
    } 
  }

  const nextPageLoader = async () => {
    try {
      await startLoader();
      await loaderDataInit(nextLink);
      await stopLoader();
    } catch (error) {
      await stopLoader();
      console.log(error)
        showSnackbar(
          "Error : " + error.message,
          "error"
        );
    }
    
  }


  return (
      <Table 
        columnsFrm={columnsFrm} 
        valuesRegister={valuesRegister}
        nextLink={nextLink}
        nextPageLoader={nextPageLoader} />
  );
} 