
import './card-component.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getItemsGraph } from "../../utils/GraphServices";
import { useUserContext } from "../../userContext"; 

export default function Card(props) {
 
    const [isHovered, setIsHovered] = useState(false);
    const [supportPending, setSupportPending] = useState(0);
    const { userSharepoint } = useUserContext();
    const navigate = useNavigate();
    const { item, initial, animate, transition } = props;
    

    const handleHover = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = ( current) => {
        navigate('/FrmSolicitud/' + current.idLista);
    }

    const goToItems= (current) => {
        navigate('/Listado/' + current.idLista);
    }

    const loadCount = async (current) => {
        let linkInit = `/items?expand=fields&filter=fields/ClienteLookupId eq '${userSharepoint.id}'`;

        let responseItems = await getItemsGraph(current.idLista, linkInit); 
        if (responseItems) {  
            setSupportPending(responseItems.data.value.filter(item => !item.fields.Estado.includes('Finalizado')).length); 
        }
    } 


    useEffect(() => {
        loadCount(item).catch(error => console.log(error)); // createForm(columnsFrm);  

        return () => {
            // Aquí se debería llamar a `destroy` solo si realmente existe
        };
    }, [])  

    return (
        <motion.div initial={initial} animate={animate} transition={transition} key={item.idLista} className='card'>
            <div className='card__container'>
                <div className={isHovered ? 'card__header hovered' : 'card__header'}>
                    <p>Solicitud</p>
                    <h4>{ item.Title }</h4>
                </div>
                <div className='card__body'>
                    <figure>
                        <img src={process.env.PUBLIC_URL + '/assets/icon/' + item.Icon +'.svg'} alt="" />
                    </figure>
                    <div className="btn">
                        <button onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} onClick={() => handleClick(item)} >
                            <b>Realizar</b> Solicitud
                        </button>
                        <button onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} onClick={() => goToItems(item)}>
                            <b>Ver</b> Solicitudes
                        </button>
                    </div>
                    <div className="counter">
                        {supportPending}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}