import React, { useState, useEffect } from 'react';
import {
  getItemsGraph, 
} from "../../utils/GraphServices";

// Este componente manejará la presentación basada en el tipo de columna y los datos de la fila.
const CustomCell = ({ columnKey, rowData }) => {
  // La función renderContent determina qué renderizar basado en el tipo de columna 
  const [lookupValue, setLookupValue] = useState('');
  const hasData = rowData && Object.keys(rowData).length > 0;

  useEffect(() => {
    if (columnKey.type === "lookup" && columnKey.lookup.listId && rowData.id) { 
      const idRecurso = rowData[`${columnKey.name}LookupId`]; 
      if (idRecurso) {
        getItemsGraph(columnKey.lookup.listId, `/items/${idRecurso}?expand=fields`)
          .then((response) => {
            setLookupValue(response.data.fields[columnKey.lookup.columnName]);
          })
          .catch((error) => {
            console.log(error);
            setLookupValue('-');
          });
      } else {
        setLookupValue('-');
      }
    }
  }, [columnKey, rowData]);



  const renderContent = () => {
   
    switch (columnKey.type) {
      case "lookup": // TODO : Las apciones de tipo columKey.name => Aplicaci_x00f3_n y la rowData => Aplicaci_x00f3_nLookupId
        return <p>{lookupValue}</p>;
      case "note":
      case "user":
      case "text":
        return <p>{rowData[columnKey.name]}</p>;
      case "choice":
        return <p className={rowData[columnKey.name] === 'Finalizado' ? 'confirmed' : ''} >{rowData[columnKey.name]}</p>;
      case "dateTime":
        return (
          <p>
            {new Date(rowData[columnKey.name]).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })}
          </p>
        );
      case "currency":
        return (
          <p>
            {rowData[columnKey.name]?.toLocaleString('es-CO', {
              style: 'currency',
              currency: 'COP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </p>
        );
      case "number":
        return <p>{rowData[columnKey.name]?.toLocaleString()}</p>;
      case "boolean":
        return (
          <input
            type='checkbox'
            checked={rowData[columnKey.name] === true}
            disabled
            className={rowData[columnKey.name] === true ? 'checkbox-selected' : ''}
          />
        );
      default:
        return <p>{rowData[columnKey.name]}</p>;
    }
  };

  // Renderiza el contenido basado en el tipo de columna
  return hasData && renderContent();
};

export default CustomCell;
