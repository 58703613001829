
import './profile-component.scss';
import { useState } from 'react';
export default function Profile(props) {

    const [profileTrigger, activeProfileTrigger] = useState(false);
    const { onClick, accounts } = props;

    const activeProfile = () => {
        if(profileTrigger){
            activeProfileTrigger(false);
        } else {
            activeProfileTrigger(true);
        }
        
    }; 

    return (
        <div className='profile'>
            <div className='profile__container'>
                <div>
                    <figure>
                        <img src={process.env.PUBLIC_URL + '/assets/profile-pic.png'} alt="" />
                    </figure>
                    <div className="trigger" onClick={activeProfile}></div>
                </div>
                <section className={`${profileTrigger ? 'active' : ''}`}>
                    <ul>
                        <li>
                            <h4>{accounts?.name}</h4>
                            <p>{accounts?.username}</p>
                        </li>
                        <li>
                            <a href="" onClick={onClick}>Cerrar Sesión</a>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    )
}