import React, { useState, useEffect } from "react";
import Card from '../../lib/card/card-component';
import ResumeTable from '../../lib/resume-table-component/resume-table-component';
import { axiosInstance } from "../../hook/setupAxiosInterceptors"; 
import {useVariableContext} from '../../variableContext';
import { useUserContext } from '../../userContext';
import { motion } from "framer-motion";


export default function Home() {

    const [solicitudes, setSolicitudes] = useState([]); 
    const { root, setRoot } = useVariableContext(); 
    const { startLoader , stopLoader  } = useUserContext();


    useEffect(() => { 
       startLoader();
        axiosInstance.get("/getAplicaciones").then(response => {
            if (response.status === 200) {
                setSolicitudes(response.data.body.items);  
                setRoot({ ListadoApp : response.data.body.items.map(x => x.fields) , ...root });
            } 
        }).finally(() => {
          stopLoader();
        }); 
    }, [])
    

    return (
      <>
        <h1>Elige el tipo de solicitud que deseas realizar</h1>
        <ResumeTable />
        {solicitudes.map((item, index) => (
          <Card
            key={index}
            item={item.fields}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2, duration: 0.5 }}
          />
        ))}
        {/* End Card Section */}
      </>
    );
}