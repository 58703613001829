import React, { useEffect, useState, useRef, createRef } from "react";
import './chat-component.scss';
import { useUserContext } from "../../userContext";
import { useSnackbar } from "../../hook/useSnackbar";
import { uploadFiles } from '../../pages/FrmSolicitud/FnSendRegister' 
import { axiosInstance } from "../../hook/setupAxiosInterceptors";
import { useMsal } from "@azure/msal-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'

export default function ChatComponent({ infoFrm, idForm, idSolicitud, item }) {

    const { showSnackbar, SnackbarComponent } = useSnackbar(); 
    const { startLoader, stopLoader } = useUserContext();
    const [ historyChat, setHistoryChat] = useState([]);
    const [fileUpload, setFileUpload] = useState([]); 
    const [textoChat, setTextoChat] = useState('');
    const [showChat, setShowChat] = useState(false);
    const [open, setOpen] = useState(false);
    const chatBodyRef = useRef(null);
    const { accounts } = useMsal();
    const idFolderMaestra = process.env.REACT_APP_ID_FOLDER_MAESTRA;
    const baseFolder = "Clientes"; 
    const fileInputRef = createRef();

    const handleClose = () => {
        setOpen(false);
        setFileUpload([]);
    };

    const generarFechaFutura = () => { 
        var fechaActual = new Date(); 
        fechaActual.setMonth(fechaActual.getMonth() + 1); 
        var fechaISO = fechaActual.toISOString(); 
        return fechaISO;
    }

    const scrollToBottom = () => {
        if (chatBodyRef.current) {
            const scrollHeight = chatBodyRef.current.scrollHeight;
            const height = chatBodyRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            chatBodyRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }

    const formatoFechaChat = (fechaISO) => {

        // Parsear la fecha ISO
        const fecha = new Date(fechaISO);

        // Extraer componentes de la fecha
        const horas = fecha.getHours().toString().padStart(2, '0');
        const minutos = fecha.getMinutes().toString().padStart(2, '0');
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // +1 porque getMonth() devuelve 0-11
        const año = fecha.getFullYear();

        // Construir la fecha en el nuevo formato "HH:MM DD/MM/AAAA"
       return `${horas}:${minutos} ${dia}/${mes}/${año}`;
    }


    const hasResponseAgente = () => {
        // Determinar si el caso está cerrado basado en el estado.
        const casoCerrado = item?.Estado === 'Finalizado';
        // Verificar si hay al menos un mensaje del usuario.
        const hayMensajeDelUsuario = !historyChat.some(mensaje => mensaje.className === 'self');

        if (casoCerrado) {
            setShowChat(casoCerrado);
        } else {
            setShowChat(hayMensajeDelUsuario);
        }
 
    }


    const setupMicrosoftGraphSubscription = async () => { 
        try { 
            await startLoader();
            let commentsResponse = await axiosInstance.get(`getComments/${idForm}/${idSolicitud}`);
            let listadoHistory = commentsResponse.data
                .filter(item => !item.text.includes('NOTA INTERNA'))
                .map(item => {
                    var itemResult = {
                        id: item.id,
                        className: item.author.loginName.includes('notificaciones@busint.com.co') ? 'user' : 'self', // 'user' | 'self'
                        name: item.author.name,
                        createdDate: formatoFechaChat(item.createdDate),
                        text: item.text
                    };
                    return itemResult;
                })
                .sort((a, b) => Number(a.id) - Number(b.id)); 

            setHistoryChat(listadoHistory);  
            scrollToBottom();
            hasResponseAgente();

            //   const subscription = await SUBSCRIPTIONS_ITEM({
            //     changeType: "updated",
            //       notificationUrl: process.env.REACT_APP_URL_API_INTERNAL + "/notifications",
            //     resource: `sites/${process.env.REACT_APP_SITE_SHAREPOINT}/lists/${idForm}`,
            //     expirationDateTime: generarFechaFutura()
            //   }); 

            //   const responseNegotiation = await POST_REQUEST(process.env.REACT_APP_URL_API_INTERNAL + '/negotiate' , {});

            //   const connection = new HubConnectionBuilder()
            //       .withUrl(responseNegotiation.data.url, {
            //         // Omitir el envío de credenciales como cookies o tokens de autenticación.
            //         //   skipNegotiation: true,
            //         //   transport: HttpTransportType.WebSockets,
            //           accessTokenFactory: () => responseNegotiation.data.accessToken // Si necesitas un token de acceso.
            //       })
            //       .configureLogging(LogLevel.Information)
            //       .build();

            //   connection.on("nombreDelEvento", data => {
            //       console.log(data);
            //   });

            //   connection.start()
            //       .then(() => console.log("Conectado"))
            //       .catch(err => console.error("Error al conectar:", err));


            await stopLoader();
        } catch (error) {
            console.error(error);
            await stopLoader();
            showSnackbar(
                "Error : " + error.message,
                "error"
            );
        }
    };

    const handleTextoChar = (evento) => {
        setTextoChat(evento.target.value);
    };

    const sendComment = async () => {
        try {
            await startLoader();
            if (textoChat.length === 0) {
                showSnackbar(
                    "No se puede enviar contenido vacio.",
                    "error"
                );
            } else {
                let commentsResponse = await axiosInstance.post(`/setComments/${idForm}/${idSolicitud}`, {
                    mensaje: textoChat
                });
                if (commentsResponse) {
                    setTextoChat('');
                }
 
                setupMicrosoftGraphSubscription();
            }
            
            await stopLoader();
        } catch (error) {
            await stopLoader();
            showSnackbar(
                "Error al enviar comentario.",
                "error"
            );
        }
        
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Obten el archivo seleccionado
        if (file) {
            const newFiles = Array.from(event.target.files);
            setFileUpload(prevFiles => [...prevFiles, ...newFiles]);
            setOpen(true);
            
        } 
    };

    const handleButtonClick = () => {
        fileInputRef.current.value = '';
        fileInputRef.current.click();
    };

    const sendUploadDocument = async () => {
        await startLoader();
        await uploadFiles(fileUpload, idFolderMaestra, `${baseFolder}/${accounts[0].username}/${infoFrm.Title}/${idSolicitud}/`);
        let commentsResponse = await axiosInstance.post(`/setComments/${idForm}/${idSolicitud}`, {
            mensaje: 'NOTA INTERNA: Documento adjunto'
        });
        setOpen(false);
        await stopLoader();
    }

    useEffect(() => {
        setupMicrosoftGraphSubscription(); 
        return () => {};
    }, [item]);


    return (
        <div className='chat'>
            {SnackbarComponent}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {" ¿ Se encuentra seguro de adjuntar el documento ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se enviará a busint el documento con nombre {fileUpload[0]?.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={sendUploadDocument} autoFocus>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="chat__container">
                <div className="chat__header">
                    <div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/box-img.png'} alt="" className=''/>
                        </div>
                        <div>
                            <h4>Chat</h4>
                            <h6>{infoFrm?.Title}</h6>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setupMicrosoftGraphSubscription()} className='phone'></button>
                    </div>
                </div>
                <div className="chat__body" ref={chatBodyRef}>
                    {
                        historyChat && historyChat.map((item , ind) => (
                            <div key={'historyid_' + ind} className={item.className}>
                                <p> {item.name} <span> {item.createdDate} </span></p>
                                <div>
                                    {item.text}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="chat__footer">
                    <input type="text" value={textoChat} onChange={handleTextoChar} placeholder={showChat ? 'Se habilitará el chat, tan pronto el agente de respuesta.' : 'Escriba su mensaje..'} disabled={showChat} />
                    <div>
                        <div>
                            {/* <button className='icons'></button> */}
                            {
                                showChat ?
                                <div></div>
                                : 
                                <>
                                <input 
                                    style={{ display: 'none' }}
                                    // disabled={true}
                                    type="file" 
                                    id="image-upload-dev" 
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <button className='file' type="file" onClick={handleButtonClick}  ></button>
                                </>
                            }
                            
                        </div>
                        <div>
                            {
                                showChat ?
                                <div></div>
                                : 
                                <button type='submit' onClick={async () => await sendComment()}>
                                    <span> Enviar mensaje </span>
                                </button>
                            }
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}