import React from 'react';
import SolicitudeEndProcess from '../../lib/solicitud-end-process/solicitud-end-process-component';
import InfoSummary from '../../lib/info-summary/info-summary-component';

export default function EnvioSuccess() { 

    return (
       <>
            {/* Solicitude end Process + Info Summary 
                */}
            <SolicitudeEndProcess />
            <InfoSummary />
            {/* End Solicitude end Process + Info Summary
                */}

       </>
    );
}