export const initValueFrm = {
    "@odata.etag": "",
    "Title": "Soporte Administrativo - Operativo",
    "idLista": "6cad4cda-8b4d-4cfd-aea3-c4ad2f82cd28",
    "Habilitado": true,
    "Icon": "verde_intenso",
    "id": "1",
    "ContentType": "Elemento",
    "Modified": "2023-10-22T09:35:32Z",
    "Created": "2023-03-05T16:09:06Z",
    "AuthorLookupId": "9",
    "EditorLookupId": "9",
    "_UIVersionString": "8.0",
    "Attachments": false,
    "Edit": "",
    "LinkTitleNoMenu": "Soporte Administrativo - Operativo",
    "LinkTitle": "Soporte Administrativo - Operativo",
    "ItemChildCount": "0",
    "FolderChildCount": "0",
    "_ComplianceFlags": "",
    "_ComplianceTag": "",
    "_ComplianceTagWrittenTime": "",
    "_ComplianceTagUserId": ""
}