import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'; // Asegúrate de importar BrowserRouter
import { UserProvider } from "./userContext";
import { VariableProvider } from "./variableContext";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from './msalConfig';
import { TokenProvider } from "./context/TokenContext";

const pca = new PublicClientApplication(msalConfig);

const AppWithMsal = () => {
  return (
    <Router>
      <MsalProvider instance={pca}>
        <TokenProvider pca={pca}>
          <UserProvider>
            <VariableProvider>
              <App />
            </VariableProvider>
          </UserProvider>
        </TokenProvider>
      </MsalProvider>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWithMsal />); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
