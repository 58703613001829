import './modal-confirmation-component.scss';
import { useEffect, useState, Fragment } from 'react';
import { useUserContext } from "../../userContext";
import { useNavigate } from 'react-router-dom';
import AlertDialogError from '../../pages/FrmSolicitud/AlertDialogError';
import { useSnackbar } from "../../hook/useSnackbar";   


export default function ModalConfirmation({ setModalConfirmation, columnsFrm, dataFrm, infoFrm, sendRegister, fileUpload }) {

    
    const [openModalError, setOpenModalError] = useState(false);
    const { showSnackbar, SnackbarComponent } = useSnackbar();
    const [responseError, setResponseError] = useState({});
    const [objRequest, setObjRequest] = useState({
        objRequest: { stepComplete: 0, id: 0, webUrl: '' },
        error: null
    });

    const { userSharepoint , startLoader , stopLoader } = useUserContext(); 
    const navigate = useNavigate();

    const closeModalConfirm = () => {
        setModalConfirmation(false);
    }

    const sendDataConfirm = () => { 
        startLoader();        
        sendRegister(infoFrm, dataFrm, fileUpload, userSharepoint, objRequest.objRequest)
        .then(response => { 
            setObjRequest(response);
            if (response.error) { 
                console.log(response.error.interno);
                setOpenModalError(true);
                setResponseError(response.error.message);
                showSnackbar(
                    response.error.message,
                    "error"
                  );
            }

            if (response.objRequest.stepComplete === 4) {
                setModalConfirmation(false);
                showSnackbar(
                    "Solicitud registrada exitosamente !!!",
                    "success"
                );
                navigate('/Home');
            }
        })
        .catch(error => {
            console.log(error);
            setObjRequest(error);
            if (error.error.redireccion) {
                navigate('/Home');
            } else if (error.error?.response?.data?.error) {
                setOpenModalError(true);
                setResponseError(error.error?.response?.data?.error.error);
                console.log(error.error?.response?.data?.error); 
            }
        })
        .finally(() => {
            // finalizar el loader
            stopLoader();
        })

    }

    useEffect(() => {     
      return () => { 
      }
    }, [])
    

    return (
        <Fragment>
            {SnackbarComponent}
            <div className='modal-confirmation'>
                <div className="modal-confirmation__container">
                    <div className="close" onClick={closeModalConfirm} ></div>
                    <div className="modal-confirmation__body"> 
                        <h2>{infoFrm.Title}</h2>
                        <div>
                            {
                                columnsFrm
                                    .filter(x => x.type !== "thumbnail")
                                    .filter(x => dataFrm[x.name] !== undefined)
                                    .map((item , index) => (
                                        <div key={"id-confirm-frm" + index}>
                                            <div>
                                                <label>{item.displayName}</label>
                                            </div>
                                            <div>
                                                <p>{dataFrm[item.name]}</p>
                                            </div>
                                        </div>
                                    ))
                            }
                            {/* <div>
                                <div>
                                    <label>Aplicativo</label>
                                </div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/posint.svg'} alt="" className=''/>
                                </div>
                            </div> 
                            <div>
                                <div>
                                    <label>Archivos Adjuntos</label>
                                </div>
                                <div className='img'>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/assets/img-ex.png'} alt="" className=''/>
                                        <span>JPG</span>
                                        <p>Descripción de la imagen que soporta el problema</p>
                                    </div>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/assets/img-ex2.png'} alt="" className=''/>
                                        <span>PDF</span>
                                        <p>Descripción de la imagen que soporta el problema</p>
                                    </div>
                                </div>
                            </div>*/}   
                        </div>
                    </div>
                    <div className="modal-confirmation__btn">
                        <button className='cancel' onClick={closeModalConfirm} >Cancelar</button>
                        <button className='confirm' onClick={sendDataConfirm} >Confirmar Solicitud</button>
                    </div>
                </div>
            </div>
            <AlertDialogError open={openModalError} setOpen={setOpenModalError} response={responseError} initialData={{
                datosFrm: dataFrm
            }} />
        </Fragment>
    )
}