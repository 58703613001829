import './form-component.scss';
import { useEffect } from 'react';
import SelectComponent from '../select/select-component';
import InputTextComponent from '../input-text/input-text-component';
import InputNoteComponent from '../input-note/input-note-component';
import InputFileComponent from '../input-file/input-file-component';
import InputSelectComponent from '../input-select/input-select-component';
import InputDateComponent from '../input-date/input-date-component';
import InputBooleanComponent from '../input-boolean/input-boolean-component';
import { useForm } from 'react-hook-form'; 
import { motion } from 'framer-motion';


export default function Form(props) { 

    const { columnsFrm, handleSendInfo, setFileUpload, fileUpload } = props; 
    const { register, handleSubmit, setValue } = useForm(); 

    const idAplication = "5e16cd8f-5f1e-468a-acfd-7114c7033c86"; 

    const parameterFile = { 
        "columnGroup": "Columnas personalizadas",
        "description": "",
        "displayName": "Adjunto", 
        "hidden": false,
        "id": "aa969ba9-4a3f-44b5-a71e-076df5fa6b60", 
        "name": "Adjuntos", 
        "required": false,
        "type": "thumbnail",
        "thumbnail": {}
    }

    useEffect(() => {
        // createForm(columnsFrm);  
    }, [props])   
 

    const handleSubmitForm = (data) => { 
        handleSendInfo(data);
    };

    return (
        <motion.div
            className='form'
            initial={{ x: 100, opacity: 0 }} // Inicia fuera de la pantalla hacia la izquierda
            animate={{ x: 0, opacity: 1 }}    // Se mueve hacia su posición original y se vuelve opaco
            transition={{ duration: 0.5 }}    // Define la duración de la transición
        >
             <div className='form__container'>
                <form id='inputForm' onSubmit={handleSubmit(handleSubmitForm)}  key={'form_intence_create'}>
                    <div> 
                        { 
                            columnsFrm.map((input, index) => {  
                                switch (input.type) {
                                    case "note":
                                        return <InputNoteComponent props={input} key={input.id} register={register} /> 
                                    // case "thumbnail": // se tendra una carpta estandar por cada solicitud para gestionar bien los archivos
                                    //     return <InputFileComponent props={input} key={input.id} register={register} /> 
                                    case "choice":
                                        return input.lookup?.listId === idAplication ? <SelectComponent props={input} key={input.id} setValue={setValue} register={register} /> : <InputSelectComponent props={input} key={input.id} register={register} setValue={setValue}/>
                                    case "lookup":  
                                        return input.lookup?.listId === idAplication ? <SelectComponent props={input} key={input.id} setValue={setValue} register={register} /> : <InputSelectComponent props={input} key={input.id} register={register} setValue={setValue} />
                                    case "user":
                                        return <InputSelectComponent props={input} key={input.id} register={register} setValue={setValue}/>
                                    case "dateTime":
                                        return <InputDateComponent props={input} key={input.id} register={register} />
                                    case "currency": 
                                        return <InputTextComponent props={input} key={input.id} register={register} /> 
                                    case "text":
                                        return <InputTextComponent props={input} key={input.id} register={register} /> 
                                    case "number" :
                                        return <InputTextComponent props={input} key={input.id} register={register} /> 
                                    case "boolean":
                                        return <InputBooleanComponent props={input} key={input.id} register={register}  setValue={setValue} />
                                    default:
                                        return <></>
                                } 
                            })

                        }
                        <InputFileComponent props={parameterFile} setFileUpload={setFileUpload} fileUpload={fileUpload} key={parameterFile.id} register={register} />
                    </div> 
                    <div>
                        <input type="submit" value="Enviar Solicitud" />
                    </div>
                </form>
                {/* <div className={`${activeImagePanel ? 'image-layer active' : 'image-layer'}`}>
                    <div className="image-layer__container">
                        <form onSubmit={handleSubmitFiles(handleSubmit2)}>
                            <div>
                                <h2>Adjuntando archivo a tu solicitud</h2>
                                <div className="close" onClick={activePanelImgUpload}></div>
                            </div>
                            <div className='image'>
                                {getValuesFile("imageFile")?.length === 0 ? (<img className="image-preview" src={process.env.PUBLIC_URL + '/assets/fileuploadimg.png'} alt="Imagem Fixa" />) : (getValuesFile("imageFile")?.map((image, index) => (<img key={index} className="image-preview" src={image} alt={`Imagem ${index + 1}`}/>)))}
                                <div>
                                    <span>Adjuntar Documento</span>
                                    <button onChange={handleImageUpload} >
                                        <img src={process.env.PUBLIC_URL + '/assets/edit.svg'} alt="" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <textarea id="image-description" rows="3" cols="30" placeholder='Describa el archivo adjunto...' {...registerFiles('imageText')}></textarea>
                            </div>
                            <div>
                                <div>
                                    <div className="input-file preview">
                                      <img src={process.env.PUBLIC_URL + '/assets/thumbnail-image.png'} alt="" /> 
                                    </div>
                                    <div className="input-file">
                                        <label htmlFor="image-upload">+</label>
                                        <input type="file" id="image-upload" multiple  {...registerFiles('imageFile')} />
                                    </div>
                                </div>
                                <div>
                                    <button type="submit">
                                        <span>Adjuntar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}
            </div> 
        </motion.div>
    )
}