
export default function InputTextComponent({ props, register }) { 

    const inputTextProps = {
        name: props.name,
        id: props.id,
        type: props.type,
        autoComplete: 'off',
        // maxLength: props.text.maxLength, 
        ...(props.required ?  { required : true} : null ),
        ...register(props.name)
    };
    

    return (
        props.hidden ? <></> :
        <div>
            <label htmlFor="">
                {props.displayName}
                <span>{props.description}</span>
            </label>
                <input {...inputTextProps}   /> {/* toggle class "error" for error*/}
            {props.required ? <span className='error-message'>Debes elegir {props.displayName}</span> : <></>}
        </div>
    )
}