import './footer-component.scss';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div>
                    <a href="https://www.busint.com.co/politica-privacidad/">Términos y Condiciones</a>
                    <a href="https://www.busint.com.co/politica-privacidad/">Políticas de Privacidad</a>
                </div>
                <div>
                    <p>BUSINT ERP . Todos los derechos reservados</p>
                </div>
            </div>
        </footer>
    )
}