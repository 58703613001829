// Token.js
import React, { useEffect }  from "react"; 
import { axiosInstance } from "./setupAxiosInterceptors";
import { useMsal } from "@azure/msal-react";
import {useTokenContext} from "../context/TokenContext";

function useToken() {
  const { instance, accounts } = useMsal();
  const { token, tokenExpiresOn, saveToken, getTokenFromLocalStorage } = useTokenContext(); 

  function isTokenValid() {
    if (!token) return false;

    const now = new Date();
    if (now >= tokenExpiresOn) return false;

    return true;
  }

  async function fetchAccessToken() {
    try {
      if (accounts.length === 0) {
        throw new Error("No account found");
      }

      instance.setActiveAccount(accounts[0]);

      const request = {
        scopes: ["Chat.ReadWrite", "email", "Mail.Send", "offline_access", "openid", "profile", "Sites.Read.All", "Sites.ReadWrite.All", "User.Read",
        "User.ReadBasic.All", "AllSites.Manage", "AllSites.Read", "AllSites.Write", "EnterpriseResource.Read", "MyFiles.Read", "MyFiles.Write",
        "Project.Read", "Project.Write", "TaskStatus.Submit" ], // O los scopes que necesites
      };

      const response = await instance.acquireTokenSilent(request);
      const expiresOn = new Date(response.expiresOn * 1000); // Convertir a fecha JS si 'expiresOn' está en segundos

      return {
        accessToken: response.accessToken,
        expiresOn,
      };
    } catch (error) {
       if (error?.errorCode) {
         console.log(error?.errorCode);
         if (error.errorCode === "login_required") {
           instance.loginRedirect();
         }
       }
      console.error("Error fetching access token", error);
      return null;
    }
  } 

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      async (config) => {
        if (!isTokenValid()) {
          const newToken = await fetchAccessToken();
          if (newToken) {
            const { accessToken, expiresOn } = newToken; 
            saveToken(accessToken, expiresOn); // Guardar en el contexto
          }
        }
  
        const { accessToken } = getTokenFromLocalStorage();
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
  
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    // Limpiar el interceptor cuando el componente se desmonte
    return () => { 
    };
  }, [saveToken]); // Dependencias del useEffect 




   return {
     token,
     fetchAccessToken,
     isTokenValid,
     getTokenFromLocalStorage,
   };
}

export default useToken;
