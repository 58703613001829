import './input-file-component.scss';
import { useState, useEffect } from "react"


export default function InputFileComponent({ props, activePanelImgUpload, setFileUpload, fileUpload }) { 



    const [activeImagePanel, setActiveImagePanel] = useState(false);
    const [activeInputPanel, setActiveInputPanel] = useState(true);

    useEffect(() => {
        // console.log(props)
        // console.log(props.id)
    
      return () => {
      }
    }, [])
    

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFileUpload(prevFiles => [...prevFiles, ...newFiles]); 
    };

    function activePanelImgUpload() {
        if (activeImagePanel) {
            setActiveImagePanel(false);
            setActiveInputPanel(true)
        } else {
            setActiveImagePanel(true);
            setActiveInputPanel(false)
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <label htmlFor="">
                Adjuntos
                <span>Sólo se permiten archivos JPG, PNG, PDF, Docx y xlsx. MAX 5mb</span>
            </label>
            <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row' }}  className='img'>

                {/*  
                
                <img key={index} className="image-preview" src={image} alt={`Imagem ${index + 1}`} />)))} 
                
                */}

                {
                    fileUpload.map((item , index) => (
                        <img key={'img-preview-r' + index} src={process.env.PUBLIC_URL + '/assets/img-ex.png'} alt="" className='' style={{ paddingRight: "20px", width: "120px", paddingBottom: "12px" }} />
                    ))
                }
                {/* <div className="file-trigger" onClick={activePanelImgUpload}>+</div> */}
                <div className="input-file-component">
                    <label htmlFor="image-upload-dev">+</label>
                    <input 
                        type="file" 
                        id="image-upload-dev" 
                        multiple
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </div> 
    )
}