import {
  POST_REQUEST_GRAPH,
  PUT_REQUEST_GRAPH,
  GET_REQUEST_GRAPH,
  SET_ITEMS_GRAPH,
  UPDATE_FOLDER_LINK,
  CREATE_UPLOAD_SESSION,
  CHUNK_FILE
} from "../../utils/GraphServices";


const idFolderMaestra = process.env.REACT_APP_ID_FOLDER_MAESTRA;  

// Función para crear una carpeta
async function createFolder(parentFolder, folderName) {  
  try {
    const body = {
        name: folderName,
        folder: {},
        "@microsoft.graph.conflictBehavior": "rename"
     };

    return POST_REQUEST_GRAPH(
      `/drives/${idFolderMaestra}/root:/${parentFolder}:/children`,
      body
    );  
  } catch (error) {
    console.log(error);
  }
    // return responseFile.data;
}

 
async function uploadChunk(uploadUrl, chunk, startByte, totalBytes) {
  const endByte = startByte + chunk.size - 1;
  const contentRange = `bytes ${startByte}-${endByte}/${totalBytes}`;

  const response = await CHUNK_FILE(uploadUrl , contentRange , chunk); 

  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error('La subida en Chunk upload failed');
  }
};


export const uploadFiles = async (files, parentId , extraFile) => {
  for (const file of files) {
    const uploadSession = await CREATE_UPLOAD_SESSION(parentId, extraFile + file.name);
    const uploadUrl = uploadSession.data.uploadUrl; // This URL is used for uploading chunks

    const chunkSize = 58 * 1024 * 1024; // 58 MB
    let startByte = 0;

    while (startByte < file.size) {
      const chunk = file.slice(startByte, startByte + chunkSize);
      await uploadChunk(uploadUrl, chunk, startByte, file.size);
      startByte += chunkSize;
    }
  }
};




// Función para establecer permisos de una carpeta
async function setFolderPermissions(accessToken, siteId, itemId, permissions) {
    const endpoint = `/drive/items/${itemId}/permissions`; 
    // Agregar o actualizar permisos aquí. Ejemplo para agregar un permiso de lectura:
    const body = {
      roles: ["read"],
      // Aquí se debería especificar el grantee (usuario o grupo)
      // Ejemplo: grantee: { id: "user-or-group-id" }
    }; 

    const responseFolder = await POST_REQUEST_GRAPH(endpoint, body) 
    return responseFolder.data;
}

 

export default async function FnSendRegister(
  infoFrm,
  dataFrm,
  fileUpload,
  userSharepoint,
  objRequest
) {  
  // console.log(fileUpload);

  try {
    
      const baseFolder = "Clientes";
      const userEmail = userSharepoint.CorreoElectronico;
    
      if (objRequest.stepComplete === 4) { 
        return {
          objRequest: objRequest,
          error: new Error("La Solicitud ya se encuentra registrada en el sistema.")
        };
      }
    
      // Logica para crear los registros
      let idRegistro = objRequest.id;
      if (objRequest.stepComplete < 1) {
        const rowNewList = await SET_ITEMS_GRAPH(infoFrm.idLista, "/items", {
          fields: dataFrm,
        });
    
        idRegistro = rowNewList.data.id;
        objRequest.id = idRegistro;
        objRequest.stepComplete = 1;
      }
    
    
      let webUrl = objRequest.webUrl;
      if (objRequest.stepComplete < 2) {
        // Logica para crear las carpetas 
        try { 
          await ensureFolderExists(baseFolder, infoFrm.Title);
          await ensureFolderExists(`${baseFolder}/${infoFrm.Title}`, userEmail);
          const urlFolderFinish = await ensureFolderExists(`${baseFolder}/${infoFrm.Title}/${userEmail}`, idRegistro);
      
          webUrl = urlFolderFinish.webUrl;
          
          objRequest.webUrl = webUrl;
          objRequest.stepComplete = 2; 
        } catch (error) {
          return {
            objRequest: objRequest,
            error: new Error("Error en la creacion de carpeta: " + error.message)
          };
          // Handle errors more gracefully
         
        }
    
      }
    
      let completeUpdate = objRequest.completeUpdate;
      if (objRequest.stepComplete < 3) {
        const body = {
          campo: 'FolderLink',
          url: webUrl,
          description: "Adjuntos del ID: " + idRegistro,
        };
        const rowUpdate = await UPDATE_FOLDER_LINK(
          infoFrm.idLista,
          idRegistro,
          body
        ); 
    
        console.log(rowUpdate);
        // TODO: definir que necesitaria el link
        completeUpdate = 'Complete'; 
        objRequest.completeUpdate = completeUpdate;
        objRequest.stepComplete = 3;    
      }
    
    
      if (objRequest.stepComplete < 4) { 
        await uploadFiles(fileUpload , idFolderMaestra , `${baseFolder}/${infoFrm.Title}/${userEmail}/${idRegistro}/` );
     
        objRequest.stepComplete = 4; 
      }
    
      return {
        objRequest : objRequest,
        error: null
      };
    
  } catch (error) { 
    return {
      objRequest: objRequest,
      error: error
    };
  }
}

async function ensureFolderExists(basePath, folderName) {
  const folderPath = `/drives/${idFolderMaestra}/root:/${basePath}/${folderName}:/children`;
  const folderStatus = await getFolderStatus(folderPath);

  if (folderStatus === 404) {
    const folderResponse = await createFolder(basePath, folderName);
    return folderResponse.data;
  } else if (folderStatus !== 200) {
    throw new Error(`Failed to access folder: ${folderPath}`);
  }
}

async function getFolderStatus(folderPath) {
  try {
    const response = await GET_REQUEST_GRAPH(folderPath);
    return response.status; 
  } catch (error) {
    return error?.response?.status;
  }
}

// 1 - envio los archivos al sharepoint estructura de la carpeta /correoCliente/tipoSolicitud/fecharegistro/  => para chat sera igual pero con archivos del chat
// 1.1 si es exitoso continuo con
// 1.2 si es erroneo alguno de ellos, descarto cambios
// 2 - envio los registros JSon a la lista ( tener en cuenta el campolink para los documentos que se deben relacionar )
// 2.1 redirecciono a la siguiente pagina y le doy el chance de esperar 1 min para cancelar la solicitud y eliminaria la carpeta de la solicitud y la solicitud
// 2.2 Al dar un error de registro debo tener en cuenta que los archivos ya dieron exitoso y puede intentar volver a enviar los registros, si continua el error
// se debe eliminar los archivos y debe crear una nueva solicitud