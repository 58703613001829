import { useState, useEffect } from 'react';
import { getUserGraph } from "../../utils/GraphServices";
import { useSnackbar } from "../../hook/useSnackbar"; 

export default function SelectComponent({ props, setValue , register}) {

    const [activeSelect, setActiveSelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [listOpt, setListOpt] = useState([]);
    const { showSnackbar } = useSnackbar();
    const [error, setError] = useState(false);

    const validateSelection = () => {
        if (!selectedOption) {
            setError(true);
            return false; // Retorna falso si la validación falla
        }
        return true; // Retorna verdadero si la validación es exitosa
    };

    useEffect(() => {
        register(props.name + 'LookupId', { required: props.required });
        if (props.type === "lookup" && listOpt.length === 0) {
            getUserGraph(props.lookup.listId, "/items?expand=fields").then((response) => {
                setListOpt(response.data.value.map(itemSelect => itemSelect.fields));
            })
            .catch((error) => {
                showSnackbar(
                    "Error en obtener los campos del formulario, consulte con el admin.",
                    "error"
                );
            });
        }

        validateSelection()

        return () => { }
    }, [])

    function activeSelectArrow() {
        setActiveSelect(!activeSelect);
    }


    const handleOptionClick = (value) => {
        setSelectedOption('item_' + value);
        setValue(props.name + 'LookupId', value, { shouldValidate: true } );
        setError(false); 
    }; 

    return (
        props.hidden ? null :
            <div >
                <label htmlFor={props.id}>
                    {props.displayName}
                    <span>{props.description}</span>
                </label>
                <div onClick={activeSelectArrow} className={`select ${props.required && selectedOption === ''  ? 'error' : ''} ${activeSelect ? 'active' : ''} ${selectedOption ? 'selected' : ''}`}>
                    <div name={props.name} id={props.id} data-value={selectedOption} className={`selected-item ${selectedOption ? 'selected' : ''}`}  >{selectedOption ? null : 'Aplicaciones'}</div>
                    <ul>
                        {
                            listOpt.map((item, key) => (
                                <li key={'app_ind_uniq_' + key} onClick={() => handleOptionClick(item.id)} data-value={'item_' + item.id} >{item.muestra_cliente}</li>
                            ))
                        }
                    </ul>
                </div>
                {error && <span className='error-message'>Debes elegir {props.displayName}</span>}
            </div>
    )
}