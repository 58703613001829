const msalConfig = {
    auth: {
        clientId: 'cb235263-ce1f-44bb-993f-0b115718603c', // Reemplaza con tu Client ID de Azure AD
        authority: 'https://login.microsoftonline.com/ce18671d-5946-46f4-a7cf-279968030bc2', // Reemplaza con tu Tenant ID 
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true
    }, 
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    scopes: ["Chat.ReadWrite", "email", "Mail.Send", "offline_access", "openid", "profile", "Sites.Read.All", "Sites.ReadWrite.All", "User.Read",
        "User.ReadBasic.All", "AllSites.Manage", "AllSites.Read", "AllSites.Write", "EnterpriseResource.Read", "MyFiles.Read", "MyFiles.Write",
        "Project.Read", "Project.Write", "TaskStatus.Submit" ],
};

export default msalConfig;
