import React, { useState } from "react"; 
import './App.scss';
import Header from "./components/header/header-component";
import Main from './components/main/main-component';
import Footer from "./components/footer/footer-component";
import Loader from './lib/loader/loader-component';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from './pages/Login/Login';
import { Routes, Route } from "react-router-dom";
import {useSnackbar} from './hook/useSnackbar';
import Visor from "./components/visor/visor"; 
import { useUserContext } from "./userContext"; 
import { createSignalRContext } from "react-signalr/signalr";


function App() {

  const { SnackbarComponent } = useSnackbar();

  const { showLoader , uploadProgress } = useUserContext(); 
  
  const SignalRContext = createSignalRContext();

  return (
    <Routes>
      <Route path="/Visor" element={<Visor />} />
      <Route
        path="*"
        element={
          <>
            {showLoader && <Loader currentIndex={uploadProgress} />}
            <AuthenticatedTemplate>
              {/* <SignalRContext.Provider
                automaticReconnect={false}
                // connectEnabled={!!token}
                // accessTokenFactory={() => token}
                // dependencies={[token]} //remove previous connection and create a new connection if changed
                url={ process.env.REACT_APP_URL_API_INTERNAL}
              > 
              </SignalRContext.Provider> */}
               {SnackbarComponent}
                <Header></Header>
                <Main></Main>
                <Footer></Footer>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login></Login>
            </UnauthenticatedTemplate>
          </>
        }
      />
    </Routes>
  ); 
}




export default App;
