// userContext.js
import { createContext, useContext, useState } from 'react';

const VariableContext = createContext();

export const useVariableContext = () => {
    return useContext(VariableContext);
};

export const VariableProvider = ({ children }) => {
    const [root, setRoot] = useState({});

    return (
        <VariableContext.Provider value={{ root, setRoot }}>
            {children}
        </VariableContext.Provider>
    );
};
